<main>
  <router-outlet></router-outlet>
  <label for="language">{{ 'ROOT.LANGUAGE' | translate }}</label>
  <select id="language" #langSelect (change)="changeLanguage(langSelect.value)">
    <option
      *ngFor="let lang of translate.getLangs()"
      [value]="lang"
      [selected]="lang == translate.currentLang"
    >
      {{ lang }}
    </option>
  </select>
</main>
