{{ 'HOME.TITLE' | translate }}
<form [formGroup]="form">
  <lib-meyerhof-calc
    ngDefaultControl
    formControlName="child"
    (changeEvent)="compute($event)"
  ></lib-meyerhof-calc>
</form>
<div>
  Form Values: qref:
  {{ result?.qref | number: undefined:translateEx.language }}, b':
  {{ result?.b_ | number: undefined:translateEx.language }}
</div>
