<div [formGroup]="form">
  <lib-foundation-strip-form
    ngDefaultControl
    formControlName="foundation"
  ></lib-foundation-strip-form>
  <lib-vertical-eccentric-form
    ngDefaultControl
    formControlName="load"
  ></lib-vertical-eccentric-form>
</div>
